import { memo } from 'react'

import dynamic from 'next/dynamic'

import * as S from './AnimatedBanner.styles'
import { AnimatedBannerProps } from './AnimatedBanner.types'

const DynamicRive = dynamic(() => import('./DynamicRive'))

function AnimatedBanner({
  url,
  stateMachine,
  background
}: AnimatedBannerProps) {
  return (
    <S.Wrapper background={background}>
      <div>
        <DynamicRive url={url} stateMachine={stateMachine} />
      </div>
    </S.Wrapper>
  )
}

export default memo(AnimatedBanner)
