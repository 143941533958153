import { useEffect, useState } from 'react'

interface MotionPreferenceState {
  prefersReduced: boolean
}

const useMotionPreference = (): MotionPreferenceState => {
  const [prefersReduced, setPrefersReduced] = useState(false)

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const mediaQuery = window.matchMedia('(prefers-reduced-motion: reduce)')

      const updatePreference = () => {
        setPrefersReduced(mediaQuery.matches)
      }

      mediaQuery.addEventListener('change', updatePreference)

      updatePreference()

      return () => mediaQuery.removeEventListener('change', updatePreference)
    }
  }, [])

  return { prefersReduced }
}

export { useMotionPreference }
