import { useState } from 'react'

import { IconArrowDownChevron, IconPause } from '@k_frontend/icons'

import * as S from './BannerController.styles'
import { BannerControllerProps } from './BannerController.types'

export default function BannerController({
  bannersLength,
  dots,
  sliderStatusRef,
  refSlider,
  onScreen,
  duration
}: BannerControllerProps) {
  const [sliderStatus, setSliderStatus] = useState(true)
  const hasBanners = bannersLength > 1

  const buttonLabel = sliderStatus
    ? 'Pausar transição automática entre os banners'
    : 'Ativar transição automática entre os banners'

  function togglePause() {
    sliderStatusRef.current = !sliderStatusRef.current
    setSliderStatus(!sliderStatus)

    if (sliderStatusRef.current) {
      refSlider.current.slickPlay()
    } else {
      refSlider.current.slickPause()
    }
  }

  function pauseButton() {
    if (hasBanners)
      return (
        <S.PauseButton
          onClick={togglePause}
          id="pauseButton"
          aria-label={buttonLabel}
        >
          {!sliderStatusRef.current ? (
            <IconArrowDownChevron width={16} color="#FF6500" aria-hidden />
          ) : (
            <IconPause width={12} aria-hidden />
          )}
        </S.PauseButton>
      )
  }

  return (
    <>
      <S.ListDots
        shouldAnimateDots={onScreen && sliderStatusRef.current}
        duration={duration}
        bannersLength={bannersLength}
      >
        {dots}
      </S.ListDots>
      <S.ButtonWrapper>{pauseButton()}</S.ButtonWrapper>
    </>
  )
}
