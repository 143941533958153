import { useState, useEffect, MutableRefObject } from 'react'

function useOnScreen<T extends Element>(
  ref: MutableRefObject<T>,
  margin: string
): boolean {
  const [isIntersecting, setIsIntersecting] = useState<boolean>(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        setIsIntersecting(entry.isIntersecting)
      },
      { rootMargin: margin || '0px' }
    )

    if (ref.current) {
      observer?.observe(ref.current)
    }

    return () => {
      if (ref.current) observer.unobserve(ref.current)
    }
  }, [])

  return isIntersecting
}

export default useOnScreen
