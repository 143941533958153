import { BannerModel } from 'models/home'

export function formatBanners(banners: BannerModel[]) {
  return banners?.map((banner, index) => ({
    id: banner?.id || '',
    name: banner?.title || '',
    creative: banner?.creative || '',
    position: banner?.position || '',
    index: index + 1
  }))
}
