import styled from 'styled-components'

import { BannerSliderStyled } from './BannerSlider.types'

export const BannerSlider = styled.a<BannerSliderStyled>`
  width: 100%;
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : '#162E5B'};
`

export const WrapVertical = styled.section`
  width: 100%;
  overflow-x: hidden;

  .slick-slide {
    display: flex;
    width: 100%;
    height: auto;

    > div {
      display: flex;
      width: 100%;
      height: auto;
    }
  }

  .slick-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .slick-list {
    overflow: hidden;
    margin: 0;
  }

  .slick-track {
    height: auto !important;
  }

  img {
    width: 100%;
  }

  .slick-dots {
    display: flex;
    align-items: center;
    justify-content: center;
    bottom: ${({ theme }) => theme.spacing.sm};

    @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
      bottom: ${({ theme }) => theme.spacing.md};
    }
  }

  .slick-arrow {
    top: 50%;
    z-index: 1;
    width: ${({ theme }) => theme.spacing.lg};
    height: ${({ theme }) => theme.spacing.lg};
    background-color: ${({ theme }) => theme.colors.white(500)};
    border-radius: 100%;

    &:focus-visible {
      outline: auto -webkit-focus-ring-color;
    }

    ::before {
      content: none;
    }

    &.slick-prev {
      left: ${({ theme }) => theme.spacing.lg};
    }
    &.slick-next {
      right: ${({ theme }) => theme.spacing.lg};
    }
  }
`
