import styled from 'styled-components'

export const Wrapper = styled.div<{ background: string }>`
  width: 100%;
  height: 0;
  padding-bottom: 25.511%;
  position: relative;
  background-color: ${({ background }) => background};

  > div {
    position: absolute;
    width: 100%;
    height: 100%;
  }
`
