import styled from 'styled-components'

import { BannerControllerStyledProps } from './BannerController.types'

export const ListDots = styled.ul<BannerControllerStyledProps>`
  display: flex;
  align-items: center;
  flex: 1;
  flex-grow: 0;
  flex-basis: ${({ theme, bannersLength }) => theme.rem(bannersLength * 25)};
  gap: ${({ theme }) => theme.rem(5)};

  @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
    flex-basis: ${({ theme, bannersLength }) => theme.rem(bannersLength * 35)};
  }

  &&& {
    button {
      &:focus-visible {
        outline: auto -webkit-focus-ring-color;
      }
    }
  }

  li.slick-active button {
    position: relative;
    overflow: hidden;

    ::before {
      content: '';
      background-color: ${({ theme }) => theme.colors.secondary(500)};
      width: 0%;
      height: 100%;
      animation-name: ${({ shouldAnimateDots }) =>
        shouldAnimateDots ? 'loadNextBanner' : 'none'};
      animation-play-state: ${({ shouldAnimateDots }) =>
        shouldAnimateDots ? 'running' : 'paused'};
      animation-timing-function: linear;
      animation-duration: ${({ duration }) => `${duration}ms`};
    }

    @keyframes loadNextBanner {
      from {
        width: 0%;
      }
      to {
        width: 100%;
      }
    }
  }

  li {
    display: flex;
    flex: 1;
    flex-basis: ${({ theme }) => theme.rem(20)};
    flex-grow: 0;
    width: auto;
    height: auto;
    margin: 0;

    @media (min-width: ${({ theme }) => theme.breakpoints.minTablet}) {
      flex-basis: ${({ theme }) => theme.rem(30)};
    }
  }

  li button {
    width: 100%;
    height: ${({ theme }) => theme.rem(6)};
    background-color: ${({ theme }) => theme.colors.white(500)};
    border-radius: ${({ theme }) => theme.borderRadius.md};
    padding: 0;

    ::before {
      content: none;
    }
  }
`

export const ButtonWrapper = styled.div`
  display: flex;
`

export const PauseButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${({ theme }) => theme.spacing.md};
  height: ${({ theme }) => theme.spacing.md};
  z-index: 1;
  background-color: ${({ theme }) => theme.colors.white(500)};
  border-radius: 100%;
  ${({ theme }) => theme.font(12)}

  @media (min-width: ${({ theme }) => theme.breakpoints.minDesktop}) {
    width: ${({ theme }) => theme.spacing.lg};
    height: ${({ theme }) => theme.spacing.lg};
    ${({ theme }) => theme.font(16)}
  }

  .IconArrowDownChevron {
    transform: translateX(${({ theme }) => theme.rem(2)}) rotate(-90deg);
  }
`
