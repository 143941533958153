import { useEffect, useMemo, useRef } from 'react'
import Slider from 'react-slick'

import Image from 'next/image'

import { ResizeHOC } from '@k_frontend/core'
import { IconCarouselNext, IconCarouselPrev } from '@k_frontend/icons'
import { SrOnly } from '@k_frontend/ui'
import AnimatedBanner from 'components/AnimatedBanner'
import { useMotionPreference } from 'hooks/useMotionPreference'
import useOnScreen from 'hooks/useOnScreen'
import theme from 'theme'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import BannerController from './BannerController'
import * as S from './BannerSlider.styles'
import {
  Banner,
  BannerSliderProps,
  RenderBannerParams
} from './BannerSlider.types'

export default function BannerSlider({
  banners,
  duration,
  cookieIsMobile,
  onClick = () => {},
  onChange = () => {}
}: BannerSliderProps) {
  const INITIAL_SLIDE = 0
  const currentIndex = useRef(INITIAL_SLIDE)
  const sliderStatusRef = useRef(true)
  const refContainer = useRef()
  const refSlider = useRef(null)
  const onScreen = useOnScreen(refContainer, null)
  const { prefersReduced } = useMotionPreference()

  const settings = {
    arrow: true,
    prevArrow: (
      <button aria-label='Banner anterior'>
        <IconCarouselPrev width={18} height={18} color='#003A6A' aria-hidden />
      </button>
    ),
    nextArrow: (
      <button aria-label='Próximo banner'>
        <IconCarouselNext width={18} height={18} color='#003A6A' aria-hidden />
      </button>
    ),
    autoplay: sliderStatusRef.current,
    autoplaySpeed: duration,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    draggable: true,
    pauseOnHover: false,
    slidesPerRow: 1,
    initialSlide: currentIndex.current,
    afterChange: (current) => {
      currentIndex.current = current
    },
    appendDots: (dots) => (
      <div>
        <BannerController
          bannersLength={dots.length}
          dots={dots}
          sliderStatusRef={sliderStatusRef}
          refSlider={refSlider}
          onScreen={onScreen}
          duration={duration}
        />
      </div>
    ),
    responsive: [
      {
        breakpoint: 600,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 1023,
        settings: {
          arrows: false
        }
      },
      {
        breakpoint: 1441,
        settings: {}
      }
    ]
  }

  useEffect(() => {
    if (!onScreen) {
      refSlider.current.slickPause()
    } else if (sliderStatusRef.current) {
      refSlider.current.slickPlay()
    }
  }, [onScreen])

  function getMobileBanners(banners: Banner[]) {
    return banners
      ?.filter((banner) => !banner?.isAnimated)
      ?.map((item) => ({
        ...item,
        banner: item?.bannerMobile
      }))
  }

  function getDesktopBanners(banners: Banner[]) {
    const shouldSkipRender = prefersReduced
    if (shouldSkipRender)
      return banners?.filter((banner) => !banner?.isAnimated)

    return banners
  }

  function renderSlider({
    banners,
    getBanners,
    dimensions
  }: RenderBannerParams) {
    const bannersToRender = getBanners(banners)
    return (
      <Slider
        ref={refSlider}
        beforeChange={(_, next) => onChange(next)}
        {...settings}
      >
        {bannersToRender?.map((banner, index) => (
          <S.BannerSlider
            key={index}
            onClick={() => onClick(index)}
            href={banner.link}
            target={banner?.target}
          >
            {renderBanner(banner, dimensions)}
          </S.BannerSlider>
        ))}
      </Slider>
    )
  }

  function renderBanner(banner, dimensions) {
    if (banner.isAnimated)
      return (
        <AnimatedBanner
          url={banner?.banner}
          stateMachine={banner?.stateMachine}
          background={banner?.background}
        />
      )

    return (
      <Image
        layout='responsive'
        src={banner?.banner}
        alt={banner.title || 'Banner Principal'}
        width={dimensions.width}
        height={dimensions.height}
        quality={100}
        priority
      />
    )
  }

  return useMemo(
    () => (
      <S.WrapVertical
        ref={refContainer}
        aria-label='Banner Principal'
        data-testid='bannerPrincipal'
      >
        <SrOnly>BannerPrincipal</SrOnly>
        <ResizeHOC
          minDesktopWidth={parseInt(theme.breakpoints.minTablet)}
          cookieIsMobile={cookieIsMobile}
          MobileComponent={() =>
            renderSlider({
              banners,
              getBanners: getMobileBanners,
              dimensions: {
                width: 425,
                height: 196
              }
            })
          }
          DesktopComponent={() =>
            renderSlider({
              banners,
              getBanners: getDesktopBanners,
              dimensions: {
                width: 1568,
                height: 400
              }
            })
          }
        />
      </S.WrapVertical>
    ),
    [onScreen]
  )
}
